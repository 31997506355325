import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'

const headingParts = (text: string) => {
  const s = text.split(' ').filter(Boolean)
  const last = s.pop()
  return {
    first: s.join(' '),
    last,
  }
}

export default function VideoHero({ heading, text, ctas, source, attrs }) {
  const headingText = headingParts(heading)
  const [loaded, setLoaded] = useState(false)
  const [rendered, setRendered] = useState(false)
  const el = useRef(null)

  useEffect(() => {
    if (loaded && !rendered) {
      setRendered(true)
      setTimeout(() => {
        el && el.current && el.current.play()
      }, 100)
    }
  }, [loaded])

  return (
    <section className="relative my-8 md:my-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
      <div className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 xl:col-span-5 lg:text-left">
          <h1 className="block font-serif h-[10rem] sm:h-[12rem] md:h-40 lg:h-[14rem] w-full">
            <span className="mt-1 block text-4xl font-medium sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl">
              <span className="lg:block text-gray-900">
                {headingText.first}
              </span>
              <span className="ml-2 lg:ml-0 lg:block text-brand-600">
                {headingText.last}
              </span>
            </span>
          </h1>
          {text && (
            <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl lg:mt-4">
              {text}
            </p>
          )}
          <div className="mt-5 flex flex-col md:flex-row items-center justify-center lg:justify-start lg:mt-8">
            {ctas.map((cta, i) => (
              <div
                key={`vid-hero-${i}`}
                className={
                  i === 0
                    ? 'w-full md:w-auto rounded-md shadow'
                    : 'w-full md:w-auto mt-6 rounded-md shadow sm:mt-0 sm:ml-3'
                }
              >
                <Link
                  href={cta.link}
                  passHref
                  className={i === 0 ? 'btn-primary' : 'btn-secondary'}>

                  {cta.text}

                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-12 relative sm:max-w-lg sm:mx-auto lg:mb-0 lg:max-w-none lg:mx-0 lg:col-span-6 xl:col-span-7 lg:flex lg:items-center">
          <div className="relative w-full rounded-lg shadow-lg lg:max-w-none">
            <div className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500">
              <span className="sr-only">Watch our video to learn more</span>
              {/* {rendered && ( */}
                {/* <video
                  ref={el}
                  {...attrs}
                  poster="/video-poster.png"
                  playsInline
                  muted
                  autoPlay
                  className="object-fill w-full rounded-md overflow-hidden"
                  src="https://s3.eu-west-2.amazonaws.com/assets.solunalondon.com/book-a-consultation.mp4"
                >
                  {/* {source.map(({ src, type }, i) => (
                    <source src={src} type={type} key={`source-${i}`} />
                  ))} 
                </video> */}
              {/* )} */}
                <img
                  src="/video-poster.png"
                  className="object-fill w-full rounded-md overflow-hidden"
                  alt="Watch our video to learn more about the treatments at Soluna London"
                  width="512"
                  height="285"
                />
              {/* 
              {!rendered && (
                <button
                  title="Checkout Soluna London"
                  className="w-full absolute inset-0 z-10 flex items-center justify-center text-white"
                  onClick={() => {
                    setLoaded(true)
                  }}
                >
                  <svg
                    xmlns="https://www.w3.org/2000/svg"
                    className="h-12 w-12"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
